import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { Fragment } from 'react/jsx-runtime';
import { FormattedMessage } from 'react-intl';

import { Autocomplete as RawAutocomplete } from 'components/Autocomplete';
import { getNestedGrcOrgUnitName } from 'entities/api/Person/GrcOrganisationalUnit.util';
import PersonEntity from 'entities/api/Person/Person';
import type { SiteAssociationEntityRecord } from 'entities/api/SiteAssociation';
import { FieldsetItem } from 'forms/fields';

type AssetFieldSetProps = {
  siteAssociation: SiteAssociationEntityRecord;
};

/**
 * Display a mapping of: 'Region > Asset > Operation' to Risk Owner.
 */
export const SiteAssociationRow = ({ siteAssociation }: AssetFieldSetProps) => {
  return (
    <Fragment>
      <FieldsetItem colSpan={{ base: 12, md: 6, lg: 8 }}>
        <FormControl isDisabled>
          <FormLabel>
            <FormattedMessage id="common.asset" defaultMessage="Asset" />
          </FormLabel>
          <Input
            placeholder={getNestedGrcOrgUnitName(siteAssociation.get('site'))}
          />
        </FormControl>
      </FieldsetItem>

      <FieldsetItem colSpan={{ base: 12, md: 6, lg: 4 }}>
        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="common.risk-owner"
              defaultMessage="Risk owner"
            />
          </FormLabel>
          <RawAutocomplete
            // TODO look up valid options from bhp_people
            placeholder={PersonEntity.toString(
              siteAssociation.get('risk_owner'),
            )}
            isDisabled
          />
        </FormControl>
      </FieldsetItem>
    </Fragment>
  );
};
