import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from './Person/GrcOrganisationalUnit';
import PersonEntity, { type PersonEntityRecord } from './Person/Person';

export type SiteControlStatus = 'act' | 'na' | 'nop';

class SiteControlStatusEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_control_status/',
  };

  static fields: EntityFields<SiteControlStatusEntityFields> = {
    uuid: new Fields.IdField(),

    site_framework: new Fields.CharField(),

    site: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
    }),

    control_owner: new Fields.EntityField({
      entity: PersonEntity,
    }),

    implementation_status: new Fields.CharField(),
    implementation_status_display_en: new Fields.CharField(),
    implementation_status_display_es: new Fields.CharField(),
  };
}

export type SiteControlStatusEntityFields = {
  uuid: string;
  /** site_association.site_framework.uuid */
  site_framework: string;
  site: GrcOrganisationalUnitEntityRecord;
  control_owner: PersonEntityRecord;
  implementation_status: SiteControlStatus;
  implementation_status_display_en: string;
  implementation_status_display_es: string;
};

export type SiteControlStatusEntityRecord =
  EntityRecord<SiteControlStatusEntityFields>;

SiteControlStatusEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteControlStatusEntity,
  name: 'SiteControlStatus',
});

export default SiteControlStatusEntity;
