import {
  type GroupBase,
  type Props as ReactSelectProps,
} from 'chakra-react-select';
import { FormattedMessage, useIntl } from 'react-intl';

import { Autocomplete as BrChakraAutocomplete } from '@burnsred/ui-chakra';

type OptionType = Record<string, unknown>;

type AutocompleteProps<
  Option extends OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = ReactSelectProps<Option, IsMulti, Group>;

/**
 * Provides a react-select Autocomplete widget with Chakra fields
 *
 * Extends @burnsred/ui-chakra#Autocomplete with i18n messages for placeholder, noOptionsMessage.
 *
 * - https://github.com/csandman/chakra-react-select
 * - https://react-select.com
 */
export function Autocomplete<
  Option extends OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  menuPortalTarget,
  placeholder,
  noOptionsMessage,
  ...props
}: AutocompleteProps<Option, IsMulti, Group>) {
  const { formatMessage } = useIntl();

  return (
    <BrChakraAutocomplete
      menuPortalTarget={menuPortalTarget ?? document.body}
      placeholder={
        placeholder ?? (
          <FormattedMessage id="ui.select" defaultMessage="Select..." />
        )
      }
      noOptionsMessage={
        noOptionsMessage ??
        (() =>
          formatMessage({ id: 'ui.no-options', defaultMessage: 'No Options' }))
      }
      {...props}
    />
  );
}
