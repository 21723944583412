import { Flex, Skeleton } from '@chakra-ui/react';

import { checkboxSx, timeZoneLabelSx } from './TimeZoneSelector.styles';

export const TimeZoneSkeleton = ({ count = 6 }: { count?: number }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Flex
          key={index}
          as="li"
          className="skeleton-item"
          sx={{
            ...timeZoneLabelSx,
            backgroundColor: 'timezoneempty',
          }}
        >
          <Skeleton sx={checkboxSx} />
        </Flex>
      ))}
    </>
  );
};
