import {
  Checkbox,
  CheckboxGroup,
  Stack,
  type StackProps,
  type UseCheckboxGroupProps,
} from '@chakra-ui/react';
import type { Map } from 'immutable';
import { useCallback, useMemo } from 'react';

import type { InputProps } from '@burnsred/entity-form';
import { useLocale } from 'locales/useLocale';
import type { BasicEntityRecord } from 'types';
import { asEvent } from 'util/asEvent';
import { createLogger } from 'util/createLogger';

const log = createLogger('widget:WidgetChecklist');

export type WidgetCheckboxProps<
  T extends BasicEntityRecord,
  Many extends boolean | undefined,
> = InputProps<T, Many> &
  StackProps & {
    /** filter options that are offered for user selection */
    filterOptions: () => unknown[];

    getOptionLabel?: (option: Map<string, unknown>) => string;
  };

/**
 * Renders: CheckboxGroup > Stack > Checkbox(es)
 *
 * Pass StackProps via `componentProps`:
 *
 * ```tsx
 * <Input
 *   name="operating_contexts"
 *   component={WidgetCheckbox}
 *   componentProps={{ gap: 2, direction: 'column' }}
 *   loadOptionsFromAPI
 * />
 * ```
 *
 * // TODO document loadOptionsFromAPI
 *
 * @default direction "row"
 * @default gap "6"
 */
export function WidgetCheckbox<T extends BasicEntityRecord>(
  props: WidgetCheckboxProps<T, true>,
) {
  const { locale } = useLocale();
  /* eslint-disable unused-imports/no-unused-vars */
  const {
    name,
    index,
    onChange,
    onSubmit,
    value,
    valueInitial,
    errors,
    field,
    onChangeInput,
    options: rawOptions,
    processing,
    processingDidFail,
    filterOptions,
    getOptionLabel: _gol,
    // the props above should be an exhaustive list of WidgetInputProps so we
    // can reliably get `...stackProps`
    ...stackProps
  } = props;
  /* eslint-enable unused-imports/no-unused-vars */
  log('props: %o', props);

  const options = useMemo(() => {
    // TODO filter
    return rawOptions;
  }, [rawOptions]);

  const handleChange: UseCheckboxGroupProps['onChange'] = (newValue) => {
    const valAsEvent = asEvent({
      name,
      index,
      value: options?.filter((option: Map<string, unknown>) =>
        newValue?.includes(option.get('uuid') as string),
      ),
    });
    log('handleChange: %o', { newValue, valAsEvent });
    onChange?.(valAsEvent);
  };

  const getOptionLabel = useCallback(
    (option: BasicEntityRecord) => {
      return typeof props.getOptionLabel == 'function'
        ? props.getOptionLabel(option)
        : field?.toString(option, locale);
    },
    [field, props, locale],
  );

  return (
    <CheckboxGroup
      onChange={handleChange}
      value={value.map((val) => val?.get('uuid')).toArray()}
    >
      <Stack direction="row" gap={6} {...stackProps}>
        {options?.map((option: Map<string, unknown>) => (
          <Checkbox
            key={option?.get('uuid') as string}
            name={name}
            value={option.get('uuid') as string}
          >
            {getOptionLabel(option)}
          </Checkbox>
        ))}
      </Stack>
    </CheckboxGroup>
  );
}
