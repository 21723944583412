import { type List } from 'immutable';
import { useSelector } from 'react-redux';

import { Entity, Fields } from '@burnsred/entity';
import DuckAuth from '@burnsred/entity-duck-namespace-auth';
import { type EntityFields, type EntityRecord } from 'types';

import OrganisationalUnitEntity, {
  type OrganisationalUnitEntityRecord,
} from './OrganisationalUnit.ts';
import UserPreferencesEntity, {
  type UserPreferencesEntityRecord,
} from './UserPreferences.ts';

class PersonEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/bhp_people/v2/whoami/',
  };

  static fields: EntityFields<PersonEntityFields> = {
    uuid: new Fields.IdField(),
    user: new Fields.CharField(),
    first_name: new Fields.CharField(),
    last_name: new Fields.CharField(),
    bhp_personnel_id: new Fields.CharField(),
    bhp_personnel_type: new Fields.CharField(),
    bhp_contractor_company_name: new Fields.CharField(),
    bhp_contractor_company_code: new Fields.CharField(),
    bhp_source_system: new Fields.CharField(),
    permissions: new Fields.CharField({
      many: true,
      blank: true,
    }),

    organisational_unit: new Fields.EntityField({
      blank: true,
      entity: OrganisationalUnitEntity,
    }),
  };

  //a Public User is someone without permissions to view_globalframework (ie. not in Cube admin group)
  static isPublicUser(record: PersonEntityRecord) {
    return !this.hasPermission(record, 'cube.view_globalframework');
  }

  static hasPermission(record: PersonEntityRecord, permission: string) {
    if (record && !record?.get('permissions').isEmpty()) {
      return record.get('permissions').contains(permission);
    }
    return false;
  }

  static toString(record: PersonEntityRecord) {
    return `${record?.get('first_name')} ${record?.get('last_name')}`;
  }
}

export type PersonEntityFields = {
  uuid: string;
  user: string;
  first_name: string;
  last_name: string;
  bhp_personnel_id: string;
  bhp_personnel_type: string;
  bhp_contractor_company_name: string;
  bhp_contractor_company_code: string;
  bhp_source_system: string;
  permissions: List<string>;
  organisational_unit: OrganisationalUnitEntityRecord;
};

export type PersonEntityRecord = EntityRecord<PersonEntityFields>;

PersonEntity.duck = new DuckAuth({
  app: 'Cube',
  entity: PersonEntity,
  name: 'Person',
});

export function useCurrentUser() {
  return useSelector<unknown, PersonEntityRecord>((state: unknown) =>
    PersonEntity.duck.selectors.currentUser(state),
  );
}
export function useCurrentUserPreferences() {
  const user = useCurrentUser();
  return useSelector<unknown, UserPreferencesEntityRecord>((state) =>
    UserPreferencesEntity.duck.selectors.record(state, {
      id: user?.get('user'),
    }),
  );
}

export default PersonEntity;
