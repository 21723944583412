import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity, { toString } from './i18n/I18nText';
import { type TitleEntityFields } from './types';

class OperatingContextEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/operating_context/',
  };

  static fields: EntityFields<TitleEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
  };

  static toString = toString<OperatingContextEntityRecord>;
}

export type OperatingContextEntityRecord = EntityRecord<TitleEntityFields>;

OperatingContextEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: OperatingContextEntity,
  name: 'OperatingContext',
});

export default OperatingContextEntity;
